import $ from 'jquery';
import {
  TimelineMax,
  TweenMax
} from 'gsap';
import Splitter from 'split-html-to-chars';
// import onepage_scroll from './lib/jquery.onepage-scroll.js';
import 'slick-carousel';
import './lib/jquery.lazy.min.js';
import SimpleBar from './lib/simplebar.js';




/*lazy load img */
$(function() {
  $('.lazyload').Lazy();
});


// if ($(document).width() >= 1024 && $(document).width() < 1050) {
//   var S = $( '.portfolio-item .portfolio-info' );
//   S.each(function( index ) {
//     new SimpleBar (S[index], {
//       autoHide: false
//     });
//   });
// }


(function() {
  (function($, TweenMax) {
    return $.prototype.transform = function(prop, value) {
      var target;
      if (prop == null) {
        return this.css('transform');
      } else if (!prop) {
        this.css('transform', 'none');
        return this;
      }
      if (value != null) {
        target = {};
        target[prop] = value;
        TweenMax.set(this, target);
        return this;
      } else {
        if (this[0]._gsTransform == null) {
          TweenMax.set(this, {
            x: '+=0'
          });
        }
        return this[0]._gsTransform[prop];
      }
    };
  })(window.jQuery, window.TweenMax);

}).call(this);




let tl = new TimelineMax();
let tl_page1 = new TimelineMax();
let tl_page2 = new TimelineMax();
let tl_page3 = new TimelineMax();
let tl_page4 = new TimelineMax();
let tl_page5 = new TimelineMax();
let tl_page6 = new TimelineMax();
let tl_page7 = new TimelineMax();
let tl_page8 = new TimelineMax();

// function getTl(isNew) {
//   let tl;
//   if(isNew) {
//     tl = new TimelineMax();
//   }
//   this.tl = getTl.tl || new TimelineMax();
//   return isNew ? new TimelineMax() : this.tl;
// }


let statusMenu = false;
let statusAnim = false;
let statusAnim2 = false;
let statusAnim3 = false;
let statusAnim4 = false;
let statusAnim5 = false;
let statusAnim6 = false;
let statusAnim7 = false;
let statusAnim8 = false;
let removeMenuCounter = true;


let canGo = 1;
let scrollPeople = 0;
let scrollPortfolio = 0;
let openPortfolioPreview = false;



/// split text first page on letters
function splitText() {
  let els = document.querySelectorAll('.js-splitme'),
    statusSplit = false;
  if($(document).width() >= 1024) {
    [].forEach.call(els, function(el) {
      // outerHTML, thats *important*, no direct text nodes should be in parsed HTML
      el.outerHTML = Splitter(el.outerHTML, '<span class="letter">$</span>');
    });
    statusSplit = true;
  } else {
    if(statusSplit === true) {

    }
  }
}

splitText();





if ($(document).width() >= 1024) {
  $('#fullpage').addClass('start-fullpage');
  $('.people-slider-wrapper').addClass('start-slider-people');
  $('.portfolio-slider-wrapper').addClass('start-slider-works');

  var motionPath = MorphSVGPlugin.pathDataToBezier('#motionPath1');

  $(window).on('wheel',function(e) {

    let activeSection = $('.section.section-active'),
      activeIndex = parseInt(activeSection.attr('data-slide'));


    e = e.originalEvent;
    var direction = e.deltaY>0? 1:-1;


    if(canGo === 1 || canGo === true) {
    //   console.log('cango');
      var newslide = activeIndex + direction;
      if(newslide >= 1 && newslide <= 9) {
        outSlide(activeIndex);
        transformSlide(newslide);
      }

    } else if(!canGo && scrollPortfolio === 1 || scrollPortfolio === true) {
    //   console.log('scrollPortfolio');
      if ($(document).width() >= 1050) {
        // console.log('>1050');
        // console.log($(document).width());
        var nowActivePortfolio = $('.portfolio-item.activeSlideWork'),
          activePortfolioIndex = parseInt(nowActivePortfolio.attr('data-work'));


        var newPortfolioSlide = activePortfolioIndex + direction;

        slidePortfolio(newPortfolioSlide);
      }

    } else if(!canGo && scrollPeople === 1 || scrollPeople === true) {
    //   console.log('scrollPeople');
      var nowActiveMember = $('.people.activeSlideMember'),
        activeMemberIndex = parseInt(nowActiveMember.attr('data-member'));


      var newPeopleSlide = activeMemberIndex + direction;

      slidePeople(newPeopleSlide);

    }

  });
}
//// btns general slide on desctop
$(document).on('click', '.slide-to', function(e) {
  e.preventDefault();
  if ($(document).width() >= 1024) {

    // if open people slider
    if(!canGo && scrollPeople === 1 || scrollPeople === true) {
      let parent = $(document).find('.screen');
      let target = $('.start-slider-people');

      canGo = 1;
      scrollPeople = 0;

      if(target) {
        $('.btn-under.cloned').removeClass('hidden');
        target.css({
          'transform': 'translate3d(0, 100%, 0)'
        });
        parent.css({
          'transform': 'translate3d(0, 0%, 0)',
          'transition': 'transform 600ms'
        });
        $('.people').removeClass('activeSlideMember');
      }
    } else if(!canGo && scrollPortfolio === 1 || scrollPortfolio === true) {
      let thisSection = $('.portfolio-slider-wrapper');

      scrollPortfolio = 0;


      if(thisSection) {
        thisSection.removeClass('activeSlideWork');

        $('.portfolio-slider-wrapper.start-slider-works').css({
          'transform': 'translate3d(0, 100%, 0)'
        });
      }

    } else if(!canGo && openPortfolioPreview === 1 || openPortfolioPreview === true) {
    //   let parent = $('.seven-screen');
    //   let child = $('#portfolio');

    //   if(child) {

    //     $('.btn-under.cloned').removeClass('hidden');

    //     parent.css({
    //       'transform': 'translate3d(0, 0%, 0)',
    //       'transition': 'transform 600ms'
    //     });

    //     child.css({
    //       'transform': 'translate3d(0, 100%, 0)',
    //       'transition': 'transform 600ms'
    //     });

    //     canGo = 1;
    //     openPortfolioPreview = false;
    //   }
    }

    // if(!canGo) return;
    let target = parseInt($(this).attr('data-href'));
    transformSlide(target);

  }


});

$(document).on('click', '.slide-next', function(e) {
  e.preventDefault();
  if(!canGo) return;
  let activeSlide = parseInt($(this).closest('.screen').attr('data-slide')),
    nextSlide = activeSlide + 1;
  transformSlide(nextSlide);

});
$(document).on('click', '.slide-prev:not(.btn-under)', function(e) {
  e.preventDefault();
  if(!canGo) return;
  let activeSlide = parseInt($(this).closest('.screen').attr('data-slide')),
    prevSlide = activeSlide - 1;
  transformSlide(prevSlide);

});

$(document).on('click', '.btn-under', function(e) {
  e.preventDefault();
  if(!canGo) return;
  let activeSlide = parseInt($('.screen.section-active').attr('data-slide')),
    prevSlide = activeSlide - 1;
  transformSlide(prevSlide);

});






function transformSlide(numSlide) {
  var newslide = numSlide,
    wrapper = $('#fullpage');

  if(newslide === 1) {
    wrapper.css('transform', 'translate3d(0%, 0, 0)');
    $('.section').removeClass('section-active');
    $('.section.first-screen').addClass('section-active');
  }
  if(newslide === 2) {
    wrapper.css('transform', 'translate3d(-100%, 0, 0)');
    $('.section').removeClass('section-active');
    $('.section.second-screen').addClass('section-active');
    startAnim2();
  }
  if(newslide === 3) {
    wrapper.css('transform', 'translate3d(-100%, -100%, 0)');
    $('.section').removeClass('section-active');
    $('.section.third-screen').addClass('section-active');
    startAnim3();
  }
  if(newslide === 4) {
    wrapper.css('transform', 'translate3d(-100%, -200%, 0)');
    $('.section').removeClass('section-active');
    $('.section.four-screen').addClass('section-active');
    startAnim4();
  }
  if(newslide === 5) {
    wrapper.css('transform', 'translate3d(-100%, -300%, 0)');
    $('.section').removeClass('section-active');
    $('.section.five-screen').addClass('section-active');
    startAnim5();
  }
  if(newslide === 6) {
    wrapper.css('transform', 'translate3d(-200%, -300%, 0)');
    $('.section').removeClass('section-active');
    $('.section.six-screen').addClass('section-active');
    startAnim6();
  }
  if(newslide === 7) {
    wrapper.css('transform', 'translate3d(-300%, -300%, 0)');
    $('.section').removeClass('section-active');
    $('.section.seven-screen').addClass('section-active');
    startAnim7();
  }
  if(newslide === 8) {
    wrapper.css('transform', 'translate3d(-300%, -400%, 0)');
    $('.section').removeClass('section-active');
    $('.section.eight-screen').addClass('section-active');
    // startAnim8();
  }
  if(newslide === 9) {
    wrapper.css('transform', 'translate3d(-400%, -400%, 0)');
    $('.section').removeClass('section-active');
    $('.section.nine-screen').addClass('section-active');
    startAnim9();
  }


  if(newslide < 2) {

    $('.menu__item').removeClass('is-active');
  } else if(newslide === 7 || newslide === 8) {

    $('.menu__item').removeClass('is-active');
    $('.menu__item:eq(5)').addClass('is-active');
  } else if(newslide === 9) {

    $('.menu__item').removeClass('is-active');
    $('.menu__item:eq(6)').addClass('is-active');
  } else {

    $('.menu__item').removeClass('is-active');
    $('.menu__item:eq(' + (newslide - 2) + ')').addClass('is-active');
  }


  btnOnPress();
  $('.btn-under:not(.cloned)').removeClass('hidden');

  canGo = false;
  setTimeout(function() {
    canGo = true;
  }, 1200);
}

function outSlide(sectionLeave) {
  var oldSlide = sectionLeave;


  if(oldSlide === 2) {
    statusAnim2 = false;
  }
  if(oldSlide === 3) {
    statusAnim3 = false;
  }
  if(oldSlide === 4) {
    statusAnim4 = false;
  }
  if(oldSlide === 5) {
    statusAnim5 = false;
  }
  if(oldSlide === 6) {
    statusAnim6 = false;
  }
  if(oldSlide === 7) {
    statusAnim7 = false;
  }
  if(oldSlide === 8) {
    statusAnim8 = false;
  }
}

// people slider desctop
function slidePeople(newPeopleSlide) {
  var toSlideIndex = parseInt(newPeopleSlide),
    target =  $('.start-slider-people'),
    corr = (1 / 8) * 100,
    targetTransfer = (((toSlideIndex - 1) * corr) * -1) + '%';

  //   console.log(toSlideIndex);

  if(toSlideIndex >= 1 && toSlideIndex < 9 && target) {
    var nextSlide = $('.people[data-member='+ toSlideIndex +']');

    // console.log(nextSlide);

    $('.people').removeClass('activeSlideMember');
    nextSlide.addClass('activeSlideMember');

    target.css({
      'transform': 'translate3d('+ targetTransfer +', 0%, 0)'
    });

  } else {
    $('.start-slider-people').css('transform', 'translate3d(0%, 100%, 0)');

    $('.people').removeClass('activeSlideMember');
    $(document).find('.screen').css({
      'transform': 'translate3d(0, 0%, 0)',
      'transition': 'transform 600ms'
    });
    outSlider();
  }

  scrollPeople = 0;
  setTimeout(function() {
    scrollPeople = 1;
  }, 800);
}
function outSlider() {
  setTimeout(function() {
    scrollPeople = 0;
    canGo = 1;
  }, 810);
}

//portfolio slider desctop
function slidePortfolio(newWorkSlid) {
  var toSlideIndex = parseInt(newWorkSlid),
    target =  $('.start-slider-works'),
    corr = (1 / 7) * 100,
    targetTransfer = (((toSlideIndex - 1) * corr) * -1) + '%';


  if(toSlideIndex >= 1 && toSlideIndex < 8 && target) {
    var nextSlide = $('.portfolio-item[data-work='+ toSlideIndex +']');

    // console.log(nextSlide);

    $('.portfolio-item').removeClass('activeSlideWork');
    nextSlide.addClass('activeSlideWork');

    target.css({
      'transform': 'translate3d('+ targetTransfer +', 0%, 0)'
    });

  } else {
    $('.start-slider-works').css('transform', 'translate3d(0%, 100%, 0)');

    $('.portfolio-item').removeClass('activeSlideWork');
    // $('#portfolio').css({
    //   'transform': 'translate3d(0, 0%, 0)',
    //   'transition': 'transform 600ms'
    // });

    outSliderP();
  }

  scrollPortfolio = 0;
  setTimeout(function() {
    scrollPortfolio = 1;
  }, 800);
}


function outSliderP() {
  setTimeout(function() {
    scrollPortfolio = 0;
    canGo = 1;
  }, 810);
}

// open people slider on desctop
$(document).on('click', '.call-section-free', function() {
  let parent = $(this).closest('.screen');
  //   let parentPositionTop = parent.css('top');
  let targetId = $(this).attr('data-href');
  let target = $('div'+ targetId);
  let targetPosition = parseInt(target.attr('data-member'));
  let corr = (1 / 8) * 100;
  let targetTransfer = (((targetPosition - 1) * corr) * -1) + '%';

  canGo = 0;
  scrollPeople = 1;


  if(target) {

    $('.btn-under.cloned').addClass('hidden');

    $('.people-slider-wrapper.start-slider-people').css({
      'transform': 'translate3d('+ targetTransfer +', 0%, 0)'
    });
    target.addClass('activeSlideMember');
    // target.css({
    // //   'top': parentPositionTop,
    //   'transform': 'translate3d(0, 0%, 0)',
    //   'transition': 'transform 600ms'
    // }).addClass('activeSlideMember');
    parent.css({
      'transform': 'translate3d(0, -100%, 0)',
      'transition': 'transform 600ms'
    });
  }
  //   statusScroll = false;
});


// close people slider on desctop
$(document).on('click', '.js-close-section-free', function() {

  if ($(document).width() <= 1023) {
    $(this).closest('.people-slider-wrapper').removeClass('open');
  } else {
    let parent = $(document).find('.screen');
    let target = $(this).closest('.start-slider-people');

    canGo = 1;
    scrollPeople = 0;

    if(target) {
      $('.btn-under.cloned').removeClass('hidden');
      target.css({
        'transform': 'translate3d(0, 100%, 0)'
      });
      parent.css({
        'transform': 'translate3d(0, 0%, 0)',
        'transition': 'transform 600ms'
      });
      $('.people').removeClass('activeSlideMember');
    }

  }

});

// open page works previews
// $(document).on('click', '.call-portfolio-previews', function() {
//   let parent = $('.seven-screen');
//   let child = $('#portfolio');

//   if(child) {
//     canGo = false;
//     $('.btn-under.cloned').addClass('hidden');

//     parent.css({
//       'transform': 'translate3d(0, -100%, 0)',
//       'transition': 'transform 600ms'
//     });

//     child.css({
//       'transform': 'translate3d(0, 0%, 0)',
//       'transition': 'transform 600ms'
//     });
//     openPortfolioPreview = true;
//   }


// });
// close page works previews
$(document).on('click', '.close-preview-portfolio', function() {
  let parent = $('.seven-screen');
  let child = $('#portfolio');

  if(child) {

    $('.btn-under.cloned').removeClass('hidden');

    parent.css({
      'transform': 'translate3d(0, 0%, 0)',
      'transition': 'transform 600ms'
    });

    child.css({
      'transform': 'translate3d(0, 100%, 0)',
      'transition': 'transform 600ms'
    });

    canGo = 1;
    openPortfolioPreview = false;
  }

});
// close page works previews & move to last page
$(document).on('click', '.close-preview-portfolio-2', function() {
  let parent = $('.seven-screen');
  let child = $('#portfolio');

  if(child) {

    $('.btn-under.cloned').removeClass('hidden');

    parent.css({
      'transform': 'translate3d(0, 0%, 0)',
      'transition': 'transform 600ms'
    });

    child.css({
      'transform': 'translate3d(0, 100%, 0)',
      'transition': 'transform 600ms'
    });

    canGo = 1;
    openPortfolioPreview = false;
    transformSlide(8);
  }


});


// open slider works on desctop
$(document).on('click', '.free-section-2-level-call', function(e) {
//   let parent = $('#portfolio');
  let targetId = $(this).attr('data-href');
  let target = $('div'+ targetId);
  e.preventDefault();

  let targetPosition = parseInt(target.attr('data-work'));
  let corr = (1 / 7) * 100;
  let targetTransfer = (((targetPosition - 1) * corr) * -1) + '%';



  canGo = 0;
  scrollPortfolio = 1;

  if(target) {
    // console.log(target);

    $('.portfolio-slider-wrapper.start-slider-works').css({
      'transform': 'translate3d('+ targetTransfer +', 0%, 0)'
    });
    target.addClass('activeSlideWork');

    // parent.css({
    //   'transform': 'translate3d(0, -100%, 0)',
    //   'transition': 'transform 600ms'
    // });
  }
  //   statusScroll = false;
});



$(document).on('click', '.slide-to-last-page', function() {
  transformSlide(9);
});




$(document).on('click', '.js-close-section-2-level-free', function() {

  if ($(document).width() <= 1023) {
    $(this).closest('.portfolio-slider-wrapper').removeClass('open');
  } else {
    // let thisGrandParent = $('#portfolio');
    let thisSection = $(this).closest('.section-free');


    canGo = 1;
    scrollPortfolio = 0;


    if(thisSection) {
    //   thisGrandParent.css({
    //     'transform': 'translate3d(0, 0%, 0)',
    //     'transition': 'transform 600ms'
    //   });
      thisSection.removeClass('activeSlideWork');

      $('.portfolio-slider-wrapper.start-slider-works').css({
        'transform': 'translate3d(0, 100%, 0)'
      });
    }
  }
  //   statusScroll = true;
});







function animOutOne() {

  if ($(document).width() >= 1024) {

    // console.log('dsdf');

    tl_page1
      .to('#rocket', 0.4, {
        bezier: {
          values: motionPath,
          type: 'cubic',
          autoRotate: true
        }
      })
      .fromTo('.logo-wrapper img', 0.2, {
        y: 0,
        opacity: 1
      }, {
        y: -300,
        opacity: 0,
        ease: Power4.easeOut
      })
      .fromTo('.floor', 0.3, {
        y: 0,
        opacity: 1
      }, {
        y: 300,
        opacity: 0,
        ease: Power4.easeOut
      })
      .fromTo('.lapa-nieb', 0.2, {
        y: 0,
        opacity: 1
      }, {
        y: -300,
        opacity: 0,
        ease: Power4.easeOut
      }, '-=0.5')
      .fromTo('.lapa-roma', 0.5, {
        y: 0,
        opacity: 1
      }, {
        y: -300,
        opacity: 0,
        ease: Power4.easeOut
      }, '-=0.7')
      .fromTo('.main-line', 0.2, {
        opacity: 1
      }, {
        opacity: 0
      }, '-=0.8')
      .fromTo('.scroll-icon', 0.3, {
        opacity: 1
      }, {
        opacity: 0
      }, '-=0.8');
  }


}

//start anim fisrt page & menu
if ($(document).width() > 1024) {

  canGo = false;
  tl.set('.logo-wrapper img', {y:-300,opacity: 0});
  tl.set('.lapa-nieb', {y:-300,opacity: 0});
  tl.set('.lapa-roma', {y:-300,opacity: 0});
  tl.set('.first-title .letter', {opacity: 0});
  tl
    .fromTo('.loader', 2, {
      opacity: 1
    }, {
      opacity: 1
    })
    .fromTo('.loader', 0.5, {
      opacity: 1
    }, {
      opacity: 0,
      onComplete: function() {
        tl_page1
          .fromTo('.logo-wrapper img', 1, {
            y: -300,
            opacity: 0
          }, {
            y: 0,
            opacity: 1,
            ease: Power4.easeOut
          }, '-=0.8')
          .fromTo('.floor', 1, {
            y: 300,
            opacity: 0
          }, {
            y: 0,
            opacity: 1,
            ease: Power4.easeOut
          }, '-=0.4')
          .fromTo('.lapa-nieb', 0.5, {
            y: -300,
            opacity: 0
          }, {
            y: 0,
            opacity: 1,
            ease: Power4.easeOut
          }, '-=0.5')
          .fromTo('.lapa-roma', 0.5, {
            y: -300,
            opacity: 0
          }, {
            y: 0,
            opacity: 1,
            ease: Power4.easeOut
          }, '-=0.7')
          .staggerFromTo('.menu__item', 0.5, {
            y: 20,
            opacity: 0
          }, {
            y: 0,
            opacity: 1,
            ease: Back.easeOut.config(3)
          }, 0.05)
          .staggerFromTo('.first-title .letter', 0.5, {
            y: 20,
            opacity: 0
          }, {
            y: 0,
            opacity: 1,
            ease: Back.easeOut.config(3)
          }, 0.05, '-=.4')
          .fromTo('.main-line', 1, {
            opacity: 0
          }, {
            opacity: 1
          }, '-=2')
          .fromTo('.scroll-icon', 1, {
            opacity: 0
          }, {
            opacity: 1
          }, '-=3');
        $('.loader').remove();
        canGo = 1;
      }
    })
    .fromTo('.navigation', 0.3, {
      x: -260,
      opacity: 0
    }, {
      x: 0,
      opacity: 1
    }, '-=0.5');

  // .to('#rocket', 2, {bezier:{values:motionPath, type:'cubic', autoRotate:true}});

} else {
  tl
    .fromTo('.loader', 2, {
      opacity: 1
    }, {
      opacity: 0,
      onComplete: function() {
        $('.loader').remove();
      }
    });
}


function startAnim2() {
  if (statusAnim2 === false) {
    if ($(document).width() >= 1024) {
      tl_page2
        .fromTo('.second-top-inner', 0.7, {
          y: -300,
          opacity: 0
        }, {
          y: 0,
          opacity: 1,
          ease: Power4.easeOut
        }, '-=0.4')
        .fromTo('.second-top-left', 0.7, {
          x: -800,
          opacity: 0
        }, {
          x: 0,
          opacity: 1,
          ease: Power4.easeOut
        }, '-=0.7')
        .fromTo('.second-top-left .custom-title', 0.7, {
          opacity: 0
        }, {
          opacity: 1
        }, '-=0.7')
        .fromTo('.second-top-left .custom-text', 0.7, {
          opacity: 0
        }, {
          opacity: 1
        })
        .staggerFromTo('.custom-list li', 1.3, {
          y: -30,
          opacity: 0
        }, {
          y: 0,
          opacity: 1,
          ease: Power4.easeOut
        }, 0.1, '-=0.6')
        .fromTo('.second-middle-right .custom-title', 0.7, {
          opacity: 0
        }, {
          opacity: 1
        }, '-=1')
        .fromTo('.second-middle-right .custom-text', 0.6, {
          opacity: 0
        }, {
          opacity: 1
        }, '-=1')
        .staggerFromTo('.second-middle-right p', 1, {
          y: -30,
          opacity: 0
        }, {
          y: 0,
          opacity: 1,
          ease: Power4.easeOut
        }, 0.1, '-=0.7')
        .fromTo('.lapa-nieb2', 1, {
          y: 400,
          opacity: 0
        }, {
          y: 0,
          opacity: 1,
          ease: Power4.easeOut
        }, '-=1');
      statusAnim2 = true;

    }
  }
}

function startAnim3() {
  if (statusAnim3 === false) {
    if ($(document).width() >= 1024) {
      tl_page3
        .staggerFromTo('.third-screen .point', 1.3, {
          y: -30,
          opacity: 0
        }, {
          y: 0,
          opacity: 1,
          ease: Power4.easeOut
        }, 0.1, '+=0.4')
        .fromTo('.third-center-text', 1, {
          y: 0,
          opacity: 0
        }, {
          y: -40,
          opacity: 1,
          ease: Power4.easeOut
        }, '-=1');
      statusAnim3 = true;
      // console.log(statusAnim3);
    }
  }
}

function startAnim4() {
  if (statusAnim4 === false) {
    if ($(document).width() >= 1024) {
      tl_page4
        .fromTo('.four-screen .section-title', 1, {
          y: -300,
          opacity: 0
        }, {
          y: 0,
          opacity: 1
        })
        .fromTo('.lapa-nieb4', 1.3, {
          yPercent: 100,
          opacity: 0
        }, {
          yPercent: 0,
          opacity: 1,
          ease: Power4.easeOut
        })
        .staggerFromTo('.four-screen .text', 1.3, {
          y: -30,
          opacity: 0
        }, {
          y: 0,
          opacity: 1,
          ease: Power4.easeOut
        }, 0.3, '-=0.4');
      statusAnim4 = true;
      // console.log(statusAnim3);
    }
  }
}

function startAnim5() {
  if (statusAnim5 === false) {
    if ($(document).width() >= 1024) {
      tl_page5
        .fromTo('.five-screen .section-title', 1, {
          y: -300,
          opacity: 0
        }, {
          y: 0,
          opacity: 1
        })
        .fromTo('.five-screen .subtitle', 0.7, {
          y: -300,
          opacity: 0
        }, {
          y: 0,
          opacity: 1
        }, '-=0.9')
        .fromTo('.five-screen .subtext', 0.7, {
          y: -300,
          opacity: 0
        }, {
          y: 0,
          opacity: 1
        }, '-=1.5')
        .fromTo('.lapa-nieb5', 1, {
          yPercent: 100,
          opacity: 0
        }, {
          yPercent: 0,
          opacity: 1,
          ease: Power4.easeOut
        }, '-=0.8');
      // .fromTo('.four-screen .text', 1.3, {
      //   y: -30,
      //   opacity: 0
      // }, {
      //   y: 0,
      //   opacity: 1,
      //   ease: Power4.easeOut
      // }, '-=0.7');
      statusAnim5 = true;
      // console.log(statusAnim3);
    }
  }
}


function startAnim6() {
  if (statusAnim6 === false) {
    if ($(document).width() >= 1024) {
      tl_page6
        .staggerFromTo('.six-screen .section-text-block', 1, {
          y: -300,
          opacity: 0
        }, {
          y: 0,
          opacity: 1
        }, 0.2)
        .staggerFromTo('.section-img-team .team-member', 1, {
          opacity: 0
        }, {
          opacity: 1
        }, 0.2)
        .fromTo('.six-screen .text', 1.3, {
          yPercent: 100,
          opacity: 0
        }, {
          yPercent: 0,
          opacity: 1,
          ease: Power4.easeOut
        }, '-=1');
      statusAnim6 = true;
      // console.log(statusAnim3);
    }
  }
}


function startAnim7() {
  if (statusAnim7 === false) {
    if ($(document).width() >= 1024) {
      tl_page7
        .fromTo('#seven-line', 1, {
          opacity: 0
        }, {
          opacity: 1
        })
        .fromTo('#rocket7', 1, {
          x: -150,
          y: 50,
          opacity: 0
        }, {
          x: 0,
          y: 0,
          opacity: 1
        }, '-=0.5')
        .fromTo('.seven-screen .section-title', 1, {
          opacity: 0
        }, {
          opacity: 1
        }, '-=0.7')
        .fromTo('.seven-screen .lapa-nieb7', 1, {
          opacity: 0,
          x: -50
        }, {
          opacity: 1,
          x: 0
        }, '-=0.5');

      statusAnim7 = true;
      // console.log(statusAnim3);
    }
  }
}

function startAnim9() {
  if (statusAnim8 === false) {
    if ($(document).width() >= 1024) {
      tl_page8
        .staggerFromTo('.eight-screen .section-text > *', 1, {
          y: 300,
          opacity: 0
        }, {
          y: 0,
          opacity: 1
        }, 0.2)
        .fromTo('.eight-screen .lapa-nieb', 1, {
          y: 50,
          opacity: 0
        }, {
          y: 0,
          opacity: 1
        }, '-=0.5')
        .fromTo('.eight-screen .lapa-roma', 1, {
          y: 50,
          opacity: 0
        }, {
          y: 0,
          opacity: 1
        }, '-=0.5');


      statusAnim8 = true;
      // console.log(statusAnim3);
    }
  }
}



///menu
function triggerMenu(status) {
  // console.log('fff');
  if (status === 'open') {
    // console.log('fff-open');
    $('body').addClass('menu-active');
    $('.navigation').addClass('is-active');
    tl

      .to($('.call-menu i:first-child'), 0.3, {
        top: 0,
        rotation: 45,
        transformOrigin: '0, 0'
      })
      .to($('.call-menu i:last-child'), 0.3, {
        bottom: 0,
        rotation: -45,
        transformOrigin: '0, 0'
      }, '-=.15')
      .to($('.navigation'), 0.3, {
        x: 0
      }, '-=.3');
  } else if (status === 'close') {
    // console.log('fff-close');
    $('body').removeClass('menu-active');
    $('.navigation').removeClass('is-active');
    tl
      .to($('.call-menu i:first-child'), 0.3, {
        top: -15,
        rotation: 0,
        transformOrigin: '0, 0'
      })
      .to($('.call-menu i:last-child'), 0.3, {
        bottom: -15,
        rotation: 0,
        transformOrigin: '0, 0'
      }, '-=.15');

    if ($(document).width() <= 1023 && $(document).width() >= 768) {
      tl.to($('.navigation'), 0.3, {
        x: -300
      }, '-=.3');
    }

  }

};


$(document).on('click', '.call-menu', function() {
  if (statusMenu === false) {
    triggerMenu('open');
    statusMenu = true;
  } else {
    triggerMenu('close');
    statusMenu = false;
  }
});

function rocketsPosition() {
  if ($(document).width() >= 1024) {
    tl.set('#rocket', {
      x: 0,
      xPercent: 0,
      yPercent: 50
    });
  } else {
    $('#rocket').removeAttr('style');
  }

}

rocketsPosition();

// $(document).on('click', function() {
//   // tl.set('#rocket', {xPercent:0, yPercent:0});
//   tl.to('#rocket', 10, {bezier:{values:motionPath, type:'cubic', autoRotate:true}});
//   // tl.to('#rocket3', 5, {bezier:{values:motionPath, type:'cubic', autoRotate:true}});
// });

// let statusScroll = true;




function btnOnPress() {
  var target = $(document).find($('.section-active .btn-under'));

  $('.btn-under.cloned').remove();


  if(target.length === 1) {
    setTimeout( function() {
      var targetPositionTop = target.offset()['top'],
        targetPositionLeft = target.offset()['left'],
        positionParentLeft = $('.section-active').offset()['left'],
        positionParentTop = $('.section-active').offset()['top'];

      target.clone().appendTo($('.wrapper')).addClass('cloned').css({
        'left': targetPositionLeft - positionParentLeft,
        'top': targetPositionTop - positionParentTop,
        'z-index': 110
      });
      target.addClass('hidden');
      tl
        .fromTo('.btn-under.cloned', 1, {
          opacity: 0
        }, {
          opacity: 1
        });
    }, 600);

  }

}

let statusSliderPeople = false;
let statusSliderPortfolio = false;
let sliderPeople = $('.people-slider-wrapper');
let sliderPortfolio = $('.portfolio-slider-wrapper');

function startSliders() {

  if ($(document).width() <= 1023) {
    if (sliderPeople && statusSliderPeople === false) {
      sliderPeople.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        infinite: false
      });
      statusSliderPeople = true;
    }
    if (sliderPortfolio && statusSliderPortfolio === false) {
      sliderPortfolio.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        infinite: false
      });
      statusSliderPortfolio = true;
    }

  } else {

    if(statusSliderPeople === true) {
      sliderPeople.slick('unslick');
      statusSliderPeople = false;
    }
    if(statusSliderPortfolio === true) {
      sliderPortfolio.slick('unslick');
      statusSliderPortfolio = false;
    }
  }
}
startSliders();


///slider members $ call modal with members
$(document).on('click', '.team-mobile__item', function() {
  let index = $(this).index();
  // console.log(index);

  if ($(document).width() <= 1023 && statusSliderPeople === true) {
    // console.log('in if');

    $('.people-slider-wrapper').addClass('open');
    sliderPeople[0].slick.slickGoTo(index);
  }
});

$(document).on('click', '.people .member-prev', function() {
  if ($(document).width() <= 1023 && statusSliderPeople === true) {
    let index = $(this).closest('.slick-slide').attr('data-slick-index');
    sliderPeople[0].slick.slickGoTo(index - 1);
  }
});
$(document).on('click', '.people .member-next', function() {
  if ($(document).width() <= 1023 && statusSliderPeople === true) {
    let index = $(this).closest('.slick-slide').attr('data-slick-index');
    index++;
    sliderPeople[0].slick.slickGoTo(index);
  }
});
$(document).on('click', '.people .member-pseudo-next, .people .member-pseudo-prev', function() {
  if ($(document).width() <= 1023) {
    $(this).closest('.people-slider-wrapper').removeClass('open');
  }
});




///slider works & call modal with works
$(document).on('click', '.portfolio-small-list li', function() {
  let index = $(this).index();
  // console.log(index);

  if ($(document).width() <= 1023 && statusSliderPeople === true) {
    $('.portfolio-slider-wrapper').addClass('open');
    sliderPortfolio[0].slick.slickGoTo(index);
  }
});

$(document).on('click', '.portfolio-item .member-prev', function() {
  if ($(document).width() <= 1023 && statusSliderPeople === true) {
    let index = $(this).closest('.slick-slide').attr('data-slick-index');
    sliderPortfolio[0].slick.slickGoTo(index - 1);
  } else if ($(document).width() >= 1023 && $(document).width() <= 1050) {
    var prevSlide = parseInt($(this).closest('.portfolio-item').attr('data-work'));
    prevSlide--;
    slidePortfolio(prevSlide);
  }
});
$(document).on('click', '.portfolio-item .member-next', function() {
  if ($(document).width() <= 1023 && statusSliderPeople === true) {
    let index = $(this).closest('.slick-slide').attr('data-slick-index');
    index++;
    sliderPortfolio[0].slick.slickGoTo(index);
  } else if ($(document).width() >= 1023 && $(document).width() <= 1050) {
    var nextSlide = parseInt($(this).closest('.portfolio-item').attr('data-work'));
    nextSlide++;
    slidePortfolio(nextSlide);
  }
});
$(document).on('click', '.portfolio-item .member-pseudo-next, .portfolio-item .member-pseudo-prev', function() {
  if ($(document).width() <= 1023) {
    $(this).closest('.portfolio-slider-wrapper').removeClass('open');
  } else if ($(document).width() >= 1023 && $(document).width() <= 1050) {
    let thisGrandParent = $('#portfolio');
    let thisSection = $(this).closest('.section-free');


    // canGo = 1;
    scrollPortfolio = 0;

    if(thisSection) {
      thisGrandParent.css({
        'transform': 'translate3d(0, 0%, 0)',
        'transition': 'transform 600ms'
      });
      thisSection.removeClass('activeSlideWork');

      $('.portfolio-slider-wrapper.start-slider-works').css({
        'transform': 'translate3d(0, 100%, 0)'
      });
    }
  }
});






// $(document).on('click', '.btn-under.slide-prev', function() {
//   $('#fullpage').moveUp();
//   btnOnPress();
// });

// $(document).on('mousewheel DOMMouseScroll MozMousePixelScroll', function(event) {
//   btnOnPress();
//   console.log(statusScroll);

//   if(statusScroll === true) {
//     $(document).trigger('play-scroll');
//     $('#fullpage').disable();
//   }


// });

if ($(document).width() <= 1023 && $('.navigation').is('.is-active')) {
  // console.log('menu open');
  triggerMenu('close');
  statusMenu = false;
}


var aForResize;
$(window).resize(function() {
  // console.log('ds');

  clearTimeout(aForResize);

  aForResize = setTimeout(function() {


    if ($(document).width() <= 1023 && statusMenu === true) {
      $('.navigation').css('transform', 'matrix(1, 0, 0, 1, -300, 0)');
      statusMenu = false;
    }

    if ($(document).width() <= 1023) {
      $('#fullpage').removeClass('start-fullpage');
      $('.people-slider-wrapper').removeClass('start-slider-people');
      $('.portfolio-slider-wrapper').removeClass('start-slider-works');
    }

    if ($(document).width() > 1023) {
      btnOnPress();
    }


    startSliders();


    if ($(document).width() <= 767 && removeMenuCounter === true) {
      $('body').removeClass('menu-active');
      $('.call-menu i:first-child').css(' ');
      $('.call-menu i:last-child').css(' ');
      $('.navigation').css(' ');
      statusMenu = false;
      removeMenuCounter = false;
    }

    rocketsPosition();
    splitText();
  },300);
});



if ($(document).width() >= 768 && $(document).width() < 1023) {
  var lastId,
    topMenu = $('.navigaton__menu'),
    menuItems = topMenu.find('a.slide-to'),
    scrollItems = menuItems.map(function() {
      var item = $($(this).attr('href'));
      if (item.length) { return item; }
    });

  menuItems.click(function(e) {
    var href = $(this).attr('href'),
      offsetTop = href === '#' ? 0 : $(href).offset().top;
    $('html, body').stop().animate({
      scrollTop: offsetTop
    }, 600);
    e.preventDefault();
  });

  $(window).scroll(function() {
    var fromTop = $(this).scrollTop(),
      activeNow = $('.menu__item.is-active');

    var cur = scrollItems.map(function() {
      if (($(this).offset().top - 100) < fromTop)
        return this;
    });

    cur = cur[cur.length-1];
    var id = cur && cur.length ? cur[0].id : '';

    // console.log(id);
    if(activeNow) {
      // console.log(activeNow);

    }

    if (lastId !== id) {
      lastId = id;
      menuItems
        .parent('.menu__item').removeClass('is-active')
        .end().filter("[href='#"+id+"']").parent('.menu__item').addClass('is-active');
    }
  });
}
if ($(document).width() < 768) {

  var lastId,
    topMenu = $('.navigaton__menu'),
    menuItems = topMenu.find('a.scroll-to'),
    scrollItems = menuItems.map(function() {
      var item = $($(this).attr('href'));
      if (item.length) { return item; }
    });

  menuItems.click(function(e) {
    var href = $(this).attr('href'),
      offsetTop = href === '#' ? 0 : $(href).offset().top;
    $('html, body').stop().animate({
      scrollTop: offsetTop
    }, 600);
    e.preventDefault();
  });

  $(window).scroll(function() {
    var fromTop = $(this).scrollTop(),
      activeNow = $('.menu__item.is-active');

    var cur = scrollItems.map(function() {
      if (($(this).offset().top - 100) < fromTop)
        return this;
    });

    cur = cur[cur.length-1];
    var id = cur && cur.length ? cur[0].id : '';

    // console.log(id);
    if(activeNow) {
    //   console.log(activeNow);

    }

    if (lastId !== id) {
      lastId = id;
      menuItems
        .parent('.menu__item').removeClass('is-active')
        .end().filter("[href='#"+id+"']").parent('.menu__item').addClass('is-active');
    }
  });

}

// var supportsOrientationChange = 'onorientationchange' in window,
//   orientationEvent = supportsOrientationChange ? 'orientationchange' : 'resize';

// window.addEventListener(orientationEvent, function() {
//   window.location.reload();
// }, false);
// var $topNavElements = $('.menu__inner .slide-to');
// $('.screen').scrollspy({
//   outCallback: function($element) {
//     $topNavElements.filter('[data-href="#' + $element.attr('id') + '"]').removeClass('active');
//   },
//   inCallback:  function($element, side) {
//     $topNavElements.filter('[data-href="#' + $element.attr('id') + '"]').addClass('active');
//   }
// });
